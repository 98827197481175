<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <!-- QUERY DATE-RANGE -->
      <v-col cols="12" lg="5">
        <v-row>
          <v-col cols="12">
            <v-radio-group row v-model="form.ach.accountingPeriod">
              <template v-slot:label>
                <div>{{ $t('views.remittances.billingCycle') }}<br></div>
              </template>
              <v-radio :label="$t('views.remittances.weekly')" value="1w"></v-radio>
              <v-radio :label="$t('views.remittances.monthly')" value="1m"></v-radio>             
            </v-radio-group>
          </v-col>
          <v-col>
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
        </v-row>
      </v-col>

      <!-- SEARCH -->
      <v-col cols="12" sm="6" lg="4">
        <v-card class="my-0" style="height: 100%;">
          <v-card-text>
            <v-container>
              <v-row align="end">
                <v-col cols="12" class="pa-0">
                  <v-radio-group row v-model="form.search.searchBy">
                    <template v-slot:label>
                      <div>{{ $t('views.merchants.searchBy') }}<br></div>
                    </template>
                    <v-radio :label="$t('views.merchants.merchantId')" value="merchantId"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-form @submit.native.prevent @submit="search">
                    <v-text-field outlined prepend-icon="mdi-magnify"
                      v-model="form.search.keyword"
                      placeholder="(Press ENTER to search)" clearable>
                    </v-text-field>
                  </v-form>
                </v-col>
                <!-- Acquirer filter -->
                <!-- <v-col cols="12" class="pa-0">
                  <div>Select Acquirers:<br></div>
                </v-col> -->
                <v-col cols="12" class="pa-0">
                  <v-select v-model="scope.acquirerId" :items="acquirers" 
                    hide-details
                    item-text="name" item-value="id"
                    :placeholder="$t('views.remittances.selectAcquirer')">
                  </v-select>
                </v-col>
              </v-row>
            </v-container>  
          </v-card-text>
        </v-card>
          
      </v-col>

      <v-col cols="12" sm="6" lg="2" style="height: 100%;" class="d-flex align-start flex-column">
        <v-btn x-large block color="warning" @click="exportCSV">
          <v-icon left>mdi-file-export</v-icon>
          結帳
        </v-btn>
        <v-btn block large color="grey darken-3" @click="forceRefresh">
          <v-icon>mdi-refresh</v-icon>
          {{ $t('views.remittances.refreshButton') }}
        </v-btn>
        <v-btn x-large block color="success" @click="exportRemittancesList">
          <v-icon left>mdi-file-export</v-icon>
          {{ $t('views.remittances.downloadExcel') }}
        </v-btn>
        <!-- <v-btn block large color="error" @click="openTransferDialog">
          <v-icon left>mdi-file-upload-outline</v-icon>
          {{ $t('views.remittances.transferButton') }}
        </v-btn> -->
      </v-col>

      <!-- LIST OF ORDERS -->
      <v-col cols="12">

        <v-card class="mt-0">
          <v-card-title>
            <span>點點股份全球有限公司撥款帳戶：應付店家 ＝ 所有店家匯費 ＋ 手續費 + 店家加減項 + 點點加減項 總合	</span>
          </v-card-title>
          <v-card-title>      
            <span>其它商店：
            {{ $t('views.remittances.transferAmount')}} = 
                {{$t('views.remittances.amount')}} 
              - {{$t('views.remittances.serviceFee') }} 
              - {{$t('views.remittances.transferFee') }}
              - 店家加減項
            </span>
          </v-card-title>
          <v-card-text>
            <SSRTable ref="list-remittances"
              :data-url="loadUrl" :data-key="'remittances'" :schema="schema">

              <div slot="recipient" slot-scope="{item}">
                <span> {{item.recipient_bank_name}} </span><br/>
                <span> {{item.recipient_bank}} </span><br/>
                <span> {{item.recipient_name}} </span><br/>
                <span> {{item.recipient_no}} </span>
                
              </div>

              <div slot="invoice" slot-scope="{item}">
                <span v-if="item.invoice_type==='b2b'"> {{$t('views.remittances.invoice.B2B')}} </span>
                <span v-else-if="item.invoice_type==='b2c'"> {{$t('views.remittances.invoice.B2C')}} </span><br/>
                <span> {{item.invoice_email}} </span><br/>
                <span> {{item.invoice_title}} </span><br/>
                <span> {{item.invoice_tax_id}} </span>
                
              </div>

              <div slot="amount" slot-scope="{item}">
                $ {{ Number(item.amount).toLocaleString() }}
              </div>
              <div slot="service_fee" slot-scope="{item}">
                $ {{ Number(item.service_fee).toLocaleString() }}
              </div>
              <div slot="transfer_fee" slot-scope="{item}">
                $ {{ Number(item.transfer_fee).toLocaleString() }}
              </div>
              <!-- <div slot="supplementary_withholding" slot-scope="{item}">
                {{ Number(item.supplementary_withholding) }}
              </div> -->
              <!-- <div slot="transfer_amount" slot-scope="{item}">
                $ {{ Number(item.transfer_amount).toLocaleString() }}
              </div> -->
              <div slot="payable" slot-scope="{item}">
                $ {{ Number(item.payable).toLocaleString() }}
              </div>
              <div slot="actions" slot-scope="{item}">
                <v-btn rounded depressed small color="blue" class="ml-1"
                  @click="showDialogOfLedgers(item)">
                  <v-icon left>mdi-list-box-outline</v-icon>
                  交易明細
                </v-btn>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <!-- <v-dialog v-model="dialog.transfer.show" width="40%" :persistent="true">
      <v-card>
        <v-card-title>
          Transfer
          <br/>
          {{$t('views.remittances.billingCycle')}}: {{ dialog.transfer.model.from }} - {{ dialog.transfer.model.to }}
          <br/>
          <h4 style="color:red">{{ $t('views.remittances.transferNotice') }}</h4>
        </v-card-title>
        <v-card-text>
          <v-menu ref="schedule-date-picker" v-model="dialog.transfer.menu"
            offset-y min-width="auto" :close-on-content-click="false"
            :return-value.sync="dialog.transfer.model.schedule" transition="scale-transition" >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field label="Scheduled date to transfer" offset-y min-width="auto"
                v-model="dialog.transfer.model.schedule"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="dialog.transfer.model.schedule" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialog.transfer.menu = false">Cancel</v-btn>
              <v-btn text color="primary"
                @click="$refs['schedule-date-picker'].save(dialog.transfer.model.schedule)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" :disabled="dialog.transfer.model.isLoading" @click="cancel">Cancel</v-btn>
          <v-btn color="error" :disabled="dialog.transfer.model.isLoading" :loading="dialog.transfer.model.isLoading" @click="transfer">Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog v-model="dialog.upload.show">
      <v-card>
        <v-card-title>
          Result 
        </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Batch reference ID</v-list-item-title>
              <v-list-item-subtitle>{{ dialog.upload.refId }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Batch description (transfer note)</v-list-item-title>
              <v-list-item-subtitle class="red--text">{{ dialog.upload.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Total amount</v-list-item-title>
              <v-list-item-subtitle>${{ Number(dialog.upload.uploadResult.amounts).toLocaleString() }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.upload.uploadResult.correct }} records are successful.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ dialog.upload.uploadResult.error }} records have errors.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <span class="text-h5">Error Records</span>
          <SSRTable ref="upload-error-records"
            :data="dialog.upload.errorRecords" :schema="dialog.upload.errorRecordSchema">
          </SSRTable>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" :disabled="dialog.upload.uploadResult.error ? true: false" @click="submit">Submit to Bank</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialog.ledgers.show">
      <v-card>
        <v-card-title>
          Ledgers 
        </v-card-title>
        <v-card-text>
          <SSRTable ref="ledgers" :data-url="loadLedgersUrl" :data-key="'ledgers'" :schema="ledgerSchema">
            <div slot="amount" slot-scope="{item}">
                $ {{ Number(item.amount).toLocaleString() }}
            </div>
            <div slot="serviceFee" slot-scope="{item}">
                $ {{ Number(item.serviceFee).toLocaleString() }}
            </div>
            <div slot="createdAt" slot-scope="{item}" class="py-1">
              <span>{{ tsToDateString(item.createdAt) }}</span>
            </div>
            <div slot="settledAt" slot-scope="{item}" class="py-1">
              <span>{{ tsToDateString(item.settledAt)  }}</span>
            </div>
          </SSRTable>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import SSRTable from '@/components/SSRTable';
  import DateRangePicker from '@/components/DateRangePicker';

  import axios from 'axios';
  import moment from 'moment';

  export default {
    components: {
      SSRTable,
      DateRangePicker,
      // ScopeSelector,
    },
   
    mounted() {
      this.fetchAcquirers();
    },

    methods: {
      onDateRangeUpdated(dates) {
        this.dates = dates;
      },
      search() {
        if (this.form.search.keyword && this.form.search.keyword.length) {
          this.form.search.queryString = `&${this.form.search.searchBy}=${this.form.search.keyword}`;
          // this.form.search.queryString = `&searchBy=${this.form.search.searchBy}&searchKeyword=${this.form.search.keyword}`;
        } else {
          this.form.search.queryString = '';
        }
        //NOTE: search keyword turns null when 'cleared' by user??
      },
      forceRefresh() {
        this.$refs['list-remittances'].loadData();
      },

      fetchAcquirers() {
        axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/acquirers`)
        .then(response => { 
          this.acquirers = response.data;
          // 設定預設值
          this.scope.acquirerId = this.acquirers.length ? this.acquirers[0].id : null;
        })
        .catch(err => {
          this.$notify({
            text: err?.response?.data?.message || 'Could not fetch acquirers.',
            type: 'error'
          });
        });
      },

      exportRemittancesList() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        window.open(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/remittances/export?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}&accountingPeriod=${this.form.ach.accountingPeriod}`
          + (this.scope.acquirerId? `&acquirerId=${this.scope.acquirerId}`: '')
        );
      },
      exportCSV() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        let result = axios.get(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/remittances/exportCSV?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}&accountingPeriod=${this.form.ach.accountingPeriod}`
          + (this.scope.acquirerId? `&acquirerId=${this.scope.acquirerId}`: '')
        ).then(result => {
          let fileurl = result?.data?.fileurl;
          if (fileurl) {
            window.open(fileurl);
            // // Construct the 'a' element
            // var link = window.document.createElement("a");
            // link.download = 'test.csv';
            // link.target = "_blank";

            // // Construct the URI
            // link.href = fileurl;
            // window.document.body.appendChild(link);
            // link.click();

            // // Cleanup the DOM
            // window.document.body.removeChild(link);
          } else {
            this.$notify({
              text: 'Something went wrong. Please retry.',
              type: 'error'
            });
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$notify({
            text: err?.response?.data?.message || 'Something went wrong. Please retry.',
            type: 'error'
          });
        });
      },

      tsToDateString(ts) {
        if (ts) {
          return moment.unix(ts).format('YYYY/MM/DD HH:mm:ss');
        }
        return '';
      },

      openTransferDialog() {
        let from = moment(this.dates[0]).startOf('day').format('YYYYMMDD');
        let to = moment(this.dates[1]).endOf('day').format('YYYYMMDD');
        this.dialog.transfer.model.from = from;
        this.dialog.transfer.model.to = to;
        this.dialog.transfer.show = true;
      },

      transfer () {
        let from =  moment(this.dialog.transfer.model.from).unix();
        let to = moment(this.dialog.transfer.model.to).unix();
        let schedule = moment(this.dialog.transfer.model.schedule).format('YYYYMMDD');
        this.dialog.transfer.isLoading = true;
        axios.post(
          // `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/records`,
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/remittances/transfer`,
          {
            schedule,
            from, 
            to,
            version: 'v10',
            acquirer: 'fubon'
          }
        )
        .then(response => {
          console.warn(`[Transfer] succeeded`, response.data);
          this.dialog.transfer.show = false;
          // Show a dialog to inform about upload result.
          this.dialog.upload.refId = response.data['refId'];
          this.dialog.upload.description = response.data['description'];
          this.dialog.upload.uploadResult = response.data['result'];
          this.dialog.upload.errorRecords = response.data['errorRecords'];
          this.dialog.upload.show = true;
          // force refresh 
          this.forceRefresh();
          this.$notify({
            text: `ACH transfer request upload succeeded.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`[Transfer] failed`, err);
          this.$notify({
            text: `ACH transfer request upload failed: ${err.response.data.message}`,
            type: 'error'
          });
        });
        
      },
      
      cancel() {
        this.dialog.transfer.show = false;
        this.dialog.transfer.model.from = null;
        this.dialog.transfer.model.to = null;
        this.dialog.transfer.model.schedule = moment().add(1, 'days').format('YYYY-MM-DD');
        this.dialog.transfer.model.batchNo = '01';
      }, 
      submit() {
        // if (!confirm('You are about to submit the ACH requests to bank. Sure to continue?'))
        //   return;
        if (!confirm('You are about to download ACH transfer file. Sure to continue?'))
          return;

        let refId = this.dialog.upload.refId;
        if (!refId) {
          console.error(`[Transfer] No ref ID?!!`);
          return;
        }

        window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/preview/${refId}`)
        // axios.post(
        //   `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ach/submit/${refId}`,
        //   { 
        //     schedule: moment(this.form.upload.model.schedule, 'YYYY-MM-DD').format('YYYYMMDD'),
        //     batchNo: this.form.upload.model.batchNo
        //   }
        // )
        // .then(response => {
        //   console.warn(`[Transfer] Submission succeeded`, response.data);
        //   this.$notify({
        //     text: `ACH transfer request submission succeeded.`,
        //     type: 'success'
        //   });

        //   //TODO: Redirect to batch result page?
        // })
        // .catch(err => {
        //   console.error(`[Transfer] Submission failed`, err);
        //   this.$notify({
        //     text: `ACH transfer request submission failed: ${err.message}`,
        //     type: 'error'
        //   });
        // })
        // .finally(() => {
        //   this.dialog.upload.show = false;
        // });
      },
      showDialogOfLedgers(item) {
        let from = moment(this.dates[0]).startOf('day').format('YYYYMMDD');
        let to = moment(this.dates[1]).endOf('day').format('YYYYMMDD');
        this.dialog.ledgers.model.from = from;
        this.dialog.ledgers.model.to = to;
        this.dialog.ledgers.model.merchantId = item.merchant_id;
        this.dialog.ledgers.show = true;
      }
    },

    data() {
      return {
        dates: [
          moment().startOf('isoWeek').subtract(1, 'w').format('YYYY-MM-DD'),
          moment().endOf('isoWeek').subtract(1, 'w').format('YYYY-MM-DD')
        ],
        scope: {
          acquirerId: null
        },
        acquirers: [ ],

        form: {
          ach: {
            accountingPeriod: '1w'
          },
          search: {
            searchBy: '',
            keyword: '',
            queryString: ''
          }
        },
        dialog: {
          transfer: {
            isLoading: false,
            show: false,
            menu: false,
            model: {
              from: null,
              to: null,
              schedule: moment().add(1, 'days').format('YYYY-MM-DD'),
              batchNo: '01'
            }
          },
          upload: {
            show: false,
            refId: undefined,
            description: '',
            uploadResult: { },
            errorRecords: { },
            errorRecordSchema: [
              { value: 'merchantId', text: 'Merchant' },
              { value: 'bankId', text: 'Rcpnt Bank' },
              { value: 'accountId', text: 'Rcpnt' },
              { value: 'accountNo', text: 'Rcpnt Account' },
              { value: 'amount', text: '$', align: 'right' },
              { value: 'note', text: 'Note' },
              { value: 'errorMsg', text: 'Error' }
            ]
          },
          ledgers: {
            isLoading: false,
            show: false,
            model: {
              from: null,
              to: null,
              merchantId: null
            }
          }
        }
      };
    },

    computed: {
      loadUrl() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/remittances/preview?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&status=closed&from=${from.unix()}&to=${to.unix()}`
          + `&accountingPeriod=${this.form.ach.accountingPeriod}`
          + `${this.form.search.queryString}`
          + (this.scope.acquirerId? `&acquirerId=${this.scope.acquirerId}`: '');
      },

      schema() {
        return [
          { value: 'merchant_id', text: this.$t('views.remittances.merchantId'), sortable: false },
          { value: 'shop_name', text: this.$t('views.remittances.shopName'), sortable: false },
          { value: 'acquirer_id', text: this.$t('views.remittances.acquirerId'), sortable: false },
          { value: 'amount', text: this.$t('views.remittances.amount'), sortable: false, align: 'end', width: 128 },
          { value: 'service_fee', text: this.$t('views.remittances.serviceFee'), sortable: false, align: 'end', width: 128  },
          { value: 'transfer_fee', text: this.$t('views.remittances.transferFee'), sortable: false, align: 'end', width: 128  },
          { value: 'surcharge', text: this.$t('views.remittances.surcharge'), sortable: false, align: 'end', width: 128  },
          { value: 'dotdot_surcharge', text: this.$t('views.remittances.dotdotSurcharge'), sortable: false, align: 'end', width: 128  },
          { value: 'payable', text: this.$t('views.remittances.transferAmount'), sortable: false, align: 'end', width: 128  },
          { value: 'recipient', text: this.$t('views.remittances.recipient'), sortable: false },
          { value: 'invoice', text: this.$t('views.remittances.invoice.fieldTitle'), sortable: false },
          { value: 'actions', text: this.$t('views.cardpay.orders.actions'), sortable: false },
        ];
      },

      loadLedgersUrl() {
        if (!this.dialog.ledgers.model.merchantId) return;
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/remittances/ledgers?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}`
          + `&merchantId=${this.dialog.ledgers.model.merchantId}`;
      },

      ledgerSchema() {
        return [
          // { value: 'merchantId', text: this.$t('views.remittances.merchantId'), sortable: false },
          { value: 'createdAt', text: this.$t('views.ledgers.createdAt'), sortable: false },
          { value: 'paymentOrderId', text: this.$t('views.ledgers.paymentOrderId'), sortable: false },
          { value: 'amount', text: this.$t('views.ledgers.amount'), sortable: false, align: 'end', width: 128 },
          { value: 'serviceFee', text: this.$t('views.ledgers.serviceFee'), sortable: false, align: 'end', width: 128  },
          { value: 'settledAt', text: this.$t('views.ledgers.settledAt'), sortable: false },
        ];
      },

    }
  }
</script>
